<template>
    <div class="puja-card" :class="{'booking' : type != 'puja'}">
        <router-link :to="'/'+this.type+'/'+puja?.slug" class="img-wrapper">
            <img @error="setAltImg" v-if="puja?.banner" :src="puja?.banner" :alt="'Banner - '+ puja?.title">
            <img v-else src="/app-assets/images/logo.png" alt="Banner Logo">
        </router-link>
        <div class="content">
            <div class="meta" v-if="type == 'puja'">
                <div class="date"><span>{{ $filters.date(puja?.created_at) }}</span></div>
            </div>
            <router-link :to="'/'+this.type+'/'+puja?.slug" class="puja-title">
                {{ puja?.title }}
                <svg v-if="type == 'puja'" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M7.96094 17.5L17.9609 7.5M17.9609 7.5H7.96094M17.9609 7.5V17.5" stroke="#38210F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
            <div class="category" v-if="type == 'puja'"><span>{{ puja?.tags?.splice(0,3).join(',') }}</span></div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['puja', 'type'],
    methods: {
        setAltImg(event) { 
            event.target.src = "/app-assets/images/logo.png" 
        } 
    },
}
</script>