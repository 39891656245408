<template>
    <div class="main">
        <section class="section blog-details-wrapper">
            <div class="container">
                <transition name="fade">
                    <loader v-if="loading" :text="loader_text"  />
                    <div v-else-if="!loading && !err" class="row">
                        <!-- <div class="col-lg-3">
                            <div class="sticky-top">
                                <h6>Related Blogs<hr></h6>
                                <blog-card :blog="b" v-for="(b,i) in related" :key="i" />
                            </div>
                        </div> -->
                        <div class="col-lg-8 offset-lg-2">
                            <div class="blog-header">
                                <div class="img-wrapper">
                                    <img draggable="false" :src="blog.banner" :alt="blog.title+' - Banner'">
                                </div>
                                <div class="meta">
                                    <div class="date">
                                        <span>{{ blog.maker_name }}</span>
                                        <span>{{ $filters.date(blog.created_at) }}</span>
                                    </div>
                                    <div class="category"><span v-for="r,i in blog?.categories">{{ r }}</span></div>
                                </div>
                                <h5 class="py-2">{{ blog.title }}</h5>
                            </div>
                            <div class="blog-description ql-editor" v-html="blog.description"></div>
                        </div>
                        <div class="col-lg-10 offset-lg-1" v-if="related.length">
                            <h6 class="mt-5">Related Blogs<hr></h6>
                            <div class="row">
                                <div class="col-lg-4" v-for="(b,i) in related" :key="i">
                                    <blog-card :blog="b" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="!loading && err">
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            slug: '',
            blog: null,
            related: [],
            err: null,
            loading: false,
            loader_text: 'Loading . . .',
        };
    },
    created() {
        this.slug = this.$route.params.slug
        this.index()
    },
    watch: {
        '$route': function(to, from) {
            this.slug = to.params.slug
            this.index()
        }
    },
    methods: {
        index() {
            this.loading = true
            axios.get('/api/user/blog-details/get?slug='+this.slug).then((res) => {
                this.loading = false
                if(res.data.status == 'success') {
                    this.blog = res.data.blog
                    this.related = res.data.related
                } else {
                    this.err = res.data.message
                }

            }).catch((error) => {
                toast.fire({'icon': 'error', 'title': 'Error Occurred!'})
                this.$router.push('/blogs')
                this.loading = false
            })
        },
    }
}
</script>