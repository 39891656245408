<template>
    <div class="log-card" ref="element">
        <div class="flex-bc">
            <div class="time">{{ $filters.timestamp(log.created_at) }}</div>
            <div class="status" :class="log.free_session ? 'text-success' : 'text-danger'">{{ log.free_session ? 'Free' : 'Paid' }}</div>
        </div>
        <div class="d-flex justify-content-between">
            <div class="content">
                <div class="name">{{ log.pandit.name }}</div>
                <div class="info" v-if="type == 'call_log'">{{ log.type+' Call' }}</div>
                <div class="info text-capitalize">{{ log.status }}</div>
                <div class="info">Duration: {{ log.duration_label ?? 'N/A' }}</div>
                <div class="info mb-2">Deduction: {{ log.total_price != null ? log.currency.symbol+log.total_price : 'N/A' }}</div>
                <router-link :to="'/'+(type == 'call_log' ? 'call' : 'chat')+'-review/'+log.crypt_id" class="btn dark small" v-if="log.status == 'completed' && !log.rating_stars">Write a Review</router-link>
                <div class="flex-sc gap-2 cursor-pointer" @click="$router.push('/'+(type == 'call_log' ? 'call' : 'chat')+'-review/'+log.crypt_id)" v-else-if="log.status == 'completed' && log.rating_stars">
                    <star-rating :size="12" v-model:rating="log.rating_stars" :increment="0.5" :active-color="'#38210F'" :star-size="30" :show-rating="false" read-only></star-rating>
                    <i class="fad fa-pencil-alt fa-lg"></i>
                </div>
            </div>
            <div class="img-wrapper">
                <!-- <router-link :to="'/transaction/'+log.transaction_crypt"> -->
                <router-link :to="'/astrologer/'+log.pandit.username">
                    <img :src="log.pandit.photo || '/app-assets/images/user.png'" alt="Pandit Photo">
                </router-link>
                <div class="info">{{ log[type == 'call_log' ? 'call_price' : 'chat_price'] != null ? log.currency.symbol+log[type == 'call_log' ? 'call_price' : 'chat_price']+'/min' : 'N/A' }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { readonly } from 'vue';

export default {
    props: ['log', 'type'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
        };
    },
    methods: {
    }
}
</script>