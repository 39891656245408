<template>
    <section class="section orange pb-0">
        <div class="container">
            <!-- moving-border -->
            <h4 class="section-title flex-bc moving-border">
                <span>{{ title }}</span>
                <router-link to="/astrologers?action=talk" class="text-dark">View All</router-link>
            </h4>
            <swiper
                :class="'talkbox-swiper'"
                :keyboard="astrologers.length > 1 ? { enabled: true } : false"
                :pagination="{ dynamicBullets: true, clickable: true }"
                :autoplay="false"
                :breakpoints="breakpoints"
                :navigation="false"
                :loop="false"
                :speed="500"
                :autoHeight="false"
                :modules="modules"
            >
                <swiper-slide v-for="(p,index) in astrologers" :key="index">
                    <talk-box :user="user" :pandit="p" />
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Autoplay, Pagination, Keyboard, Navigation } from 'swiper';
</script>
<script>
export default {
    props: ['user', 'title_type'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            astrologers: [],
            modules: [Autoplay, Pagination, Keyboard, Navigation ],
            breakpoints: {
                '0': {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                '768': {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                '1024': {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                '1300': {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
            },
        };
    },
    computed: {
        title() {
            switch (this.title_type) {
                case 'index': return 'Chat/Talk with Astrologers'; 
                case 'matchmaking': return 'Best Matchmaking Astrologers'; 
                default: return 'Chat/Talk with Astrologers';
            }
        }
    },
    created() {
        this.index()
    },
    watch: {
        title_type(to, from) {
            if(to!=from)
            this.index()
        }
    },
    methods: {
        index() {
            axios.post('/api/user/talk-section/get', {type: this.title_type}, this.config).then(res => {
                this.astrologers = res.data.astrologers
            })
        },
    }
}
</script>