<template>
    <div class="main">
        <section class="section blog-details-wrapper">
            <div class="container">
                <transition name="fade">
                    <loader v-if="loading" :text="loader_text"  />
                    <div v-else-if="!loading && !err" class="row">
                        <div class="col-lg-8">
                            <div class="blog-header">
                                <div class="img-wrapper">
                                    <img draggable="false" :src="puja.banner" :alt="puja.title+' - Banner'">
                                    <div class="category">{{ puja.tags?.join(',') }}</div>
                                </div>
                                <h5 class="pb-2">{{ puja.title }}</h5>
                            </div>
                            <div class="puja-description ql-editor" v-html="puja.description"></div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card orange sticky">
                                <div class="col-12 text-center form-group">
                                    <h4><u>Book Your Puja</u></h4>
                                    <img src="/app-assets/images/puja-thali.png" class="w-50">
                                </div>
                                <form class="row" @submit.prevent="submit()">
                                    <div class="col-12 form-group orange required">
                                        <label class="mb-2">Date</label>
                                        <VueDatePicker hide-offset-dates :min-date="today_date" :max-date="max_date" utc :year-range="[curr_year]" prevent-min-max-navigation :clearable="false" required v-model="form.date" :class="{'form-control is-invalid': errors.date}" format="dd MMM, yyyy" model-type="yyyy-mm-dd" :month-change-on-scroll="false" :enable-time-picker="false"></VueDatePicker>
                                        <span class="invalid-feedback" v-if="errors.date"><strong>{{ errors.date[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 form-group orange">
                                        <label class="mb-2">Time</label>
                                        <VueDatePicker :clearable="false" v-model="form.time" :class="{'form-control is-invalid': errors.time}" model-type="HH:mm" format="h:mm a" time-picker></VueDatePicker>
                                        <span class="invalid-feedback" v-if="errors.time"><strong>{{ errors.time[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 form-group orange">
                                        <label class="mb-2">Choose Puja Type</label>
                                        <v-select class="w-100" :options="types" :clearable="false" v-model="form.type" :reduce="i => i.value"></v-select>
                                        <span class="invalid-feedback" v-if="errors.type"><strong>{{ errors.type[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 form-group orange" v-show="form.type == 'onsite'">
                                        <label>Puja Location</label>
                                        <GMapAutocomplete @placeholder="'Search Location'" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" class="form-control" ></GMapAutocomplete>
                                    </div>
                                    <div class="col-12 form-group orange">
                                        <p class="small-text"><b>Note: </b>This booking is based on scholar availability at your desired location for an on-site puja. Final confirmation will be sent via email after admin approval and the price will be deducted from your wallet.</p>
                                        <button type="submit" :disabled="disBtn == 1" class="btn dark block">
                                            <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                            <transition name="flip" mode="out-in">
                                                <span v-if="!disBtn">Book Now @ {{ user.currency.symbol+price }}</span>
                                                <span v-else>Loading . . .</span>
                                            </transition>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="!loading && err">
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            curr_year: moment().format('YYYY'),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            slug: '',
            puja: null,
            form: new Form({
                date: '',
                time: '12:00', 
                type: 'online',
                lat: '',
                lon: '',
                tz: '',
                city: '',
            }),
            errors: [],
            max_date: moment().endOf('year').format('YYYY-MM-DD'),
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            related: [],
            disBtn: false, 
            types: [{
                label: 'Online Puja',
                value: 'online',
            },{
                label: 'Onsite Puja',
                value: 'onsite',
            },],
            err: null,
            loading: false,
            loader_text: 'Loading . . .',
        };
    },
    created() {
        this.slug = this.$route.params.slug
        this.index()
        this.emitter.on("loggedIn", async token => {
            this.token = token;
            this.config = { headers: { Authorization: `Bearer ${token}` } }
        });
    },
    watch: {
        '$route': function(to, from) {
            this.slug = to.params.slug
            this.index()
        }
    },
    computed: {
        price() {
            if(this.user.currency.code == 'INR') {
                if(this.form.type == 'online')
                    return this.puja.price_online_inr
                else
                    return this.puja.price_onsite_inr
            }
            else {
                if(this.form.type == 'online')
                    return this.puja.price_online_usd
                else
                    return this.puja.price_onsite_usd
            }
        },
        today_date() {
            if(this.form.type == 'online')
                this.form.date = moment().add(2, 'days').format('YYYY-MM-DD')
            else
                this.form.date =  moment().add(4, 'days').format('YYYY-MM-DD')
            return this.form.date
        }
    },
    methods: {
        index() {
            this.loading = true
            axios.get('/api/user/epuja-details/get?slug='+this.slug).then((res) => {
                this.loading = false
                this.emitter.emit('scroll_section')
                if(res.data.status == 'success') {
                    this.puja = res.data.epuja
                } else {
                    this.err = res.data.message
                }

            }).catch((error) => {
                toast.fire({'icon': 'error', 'title': 'Error Occurred!'})
                this.$router.push('/pujas')
                this.loading = false
            })
        },
        getAddressData(place) {
            this.form.lat = place.geometry.location.lat()
            this.form.lon = place.geometry.location.lng()
            this.form.tz = this.m2Tz(place.utc_offset_minutes)
            this.form.city = place.formatted_address
        },
        async submit() {
            if(!this.token) {
                this.emitter.emit('showlogin')
                return ;
            }
            if(this.form.type == 'onsite' && (!this.form.lat || !this.form.lon)) {
                Swal.fire({'icon': 'info', 'title': 'Please Select Location from Dropdown!', confirmButtonColor: "#ff7a4a"})
                return ;
            }
            var flag = false;
            if(this.price != 0 && this.user.wallet_amount - this.price < 0) {
                const res2 = await Swal.fire({
                    icon: 'error',
                    title: 'Low Wallet Balance.',
                    text: 'Please recharge your wallet to continue!',
                    confirmButtonText: 'Recharge',
                });
                if (!res2.isConfirmed) return;
                else this.$router.push('/astro-wallet')
            } else flag = true;
            if(flag) {
                this.disBtn = 1 ;
                await axios.post('/api/user/book-puja/'+this.slug, this.form, this.config).then((res) => {
                    this.disBtn = false ;
                    if(res.data.status == 'success') {
                        this.$router.push('/booking-detail/'+res.data.result_id)
                    }
                    else {
                        toast.fire({'icon': 'error', 'title': 'Error Occurred!'})
                    }
                }).catch((e) => {
                    if (e.response.status === 422) this.errors = e.response.data.errors;
                    this.disBtn = false ;
                    toast.fire({'icon': 'error', 'title': 'Server Error Occurred!'})
                })
            }
        },
    }
}
</script>