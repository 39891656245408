<template>
    <div class="main">
        <transition name="flip">
            <section class="section orange py-5">
                <div class="container">
                    <div class="row match-height">
                        <div class="col-10 offset-1 col-lg-6" v-if="err">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6" v-if="!mobile">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">Oops, {{ err }}</p>
                            </div>
                        </div>
                        <transition-group name="fade" appear :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
                            <div class="col-xl-3 col-lg-4 col-md-6" v-for="(r,index) in astro_rooms" :key="index" :data-index="index">
                                <router-link :to="'astrologers?room='+r.label" class="puja-card booking">
                                    <div class="img-wrapper">
                                        <img @error="setAltImg" v-if="r.image" :src="r.image" :alt="r.label+' - Room Banner'">
                                        <img v-else src="/app-assets/images/logo.png" alt="Banner Logo">
                                    </div>
                                    <div class="content">
                                        <div class="puja-title justify-content-center">
                                            {{ r.label }}
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </transition-group>
                    </div>
                </div>
            </section>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            mobile: this.isMobile(),
            astro_rooms: [],
            err: false,
        };
    },
    created() {
        this.index()
    },
    methods: {
        onBeforeEnter(el) {
            el.style.opacity = 0
        },
        onEnter(el, done) {
            gsap.to(el, {
                opacity: 1,
                delay: el.dataset.index * 0.1,
                onComplete: done
            })
        },
        onLeave(el, done) {
            gsap.to(el, {
                opacity: 0,
                delay: el.dataset.index * 0.1,
                onComplete: done
            })
        },
        index() {
            axios.get('/api/user/app-rooms/get').then((response) => {
                this.astro_rooms = response.data.astro_rooms
            })
            .catch((error) => {
                this.err = 'Server Error Occurred!'
            })
        }
    },
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s linear;
}
.fade-enter-from, .fade-leave-to {
    filter: blur(5px) ;
    opacity: 0 ;
}
</style>
