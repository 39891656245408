<template>
    <section class="section orange" ref="section">
        <div class="container">
            <div class="row">
                <transition name="fade">
                    <div v-if="!language" class="col-12 col-lg-8 offset-lg-2">
                        <h4 class="text-center mb-5" v-if="languages.length">Choose Skill Test Language</h4>
                        <div class="row justify-content-center">
                            <div class="col-6 col-lg-4" v-for="l in languages">
                                <div class="card flex-cc cursor-pointer" @click="select_lang(l)">
                                    <h4>{{ l }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="!index_type && !completed" class="col-12 col-lg-8 offset-lg-2">
                        <form @submit.prevent="submit" v-if="questions.length">
                            <h4 class="text-center mb-5">Skill Test Questionnaire</h4>
                            <div v-for="(q,index) in questions" :key="index" class="question-block">
                                <div class="question">Q{{index+1}}. {{ q.ques }}</div>
                                <div class="answers">
                                    <span @click="q.selected = 'a'" :class="{'active' : q.selected == 'a'}">{{ q.option_a }}</span>
                                    <span @click="q.selected = 'b'" :class="{'active' : q.selected == 'b'}">{{ q.option_b }}</span>
                                    <span @click="q.selected = 'c'" :class="{'active' : q.selected == 'c'}">{{ q.option_c }}</span>
                                    <span @click="q.selected = 'd'" :class="{'active' : q.selected == 'd'}">{{ q.option_d }}</span>
                                </div>
                            </div>
                            <div v-if="questions.length" class="flex-cc py-4">
                                <button type="submit" :disabled="disabled || disBtn" class="btn dark">
                                    <i v-if="disBtn" class="fas fa-spinner fa-spin"></i> Submit Test
                                </button>
                            </div>
                        </form>
                        <div class="card" v-else>
                            <div class="text-center row match-height">
                                <div class="col-lg-6 spin-mandala ty">
                                    <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                    <img src="/app-assets/images/Oops.png" alt="Oops" class="avatar">
                                </div>
                                <div class="col-lg-6">
                                    <img src="/app-assets/images/avatar.png" alt="Mandala">
                                </div>
                            </div>
                            <p class="desc" v-if="index_type != 3">
                                Oops, Looks like we are out of questions.<br><br>Please try again later.
                            </p>
                            <div class="flex-sc gap-2">
                                <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                <button type="button" @click="reset_lang()" class="btn orange-dark">Choose Another Language</button>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="!index_type && completed" class="col-12 col-lg-8 offset-lg-2">
                        <div class="card">
                            <div class="text-center row match-height">
                                <div class="col-lg-6 spin-mandala ty">
                                    <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                    <img src="/app-assets/images/TY.png" alt="TY" class="avatar">
                                </div>
                                <div class="col-lg-6">
                                    <img src="/app-assets/images/avatar.png" alt="Mandala">
                                </div>
                            </div>
                            <p class="desc">
                                Dear Scholar, <br><br>
                                Congratulations on completing the skill test! Your results are pending administrative approval. Following approval, we will proceed to the interview round. Expect the meeting link in your email after administrative clearance.<br><br>Your patience is valued during this process.<br>Best of luck!
                            </p>
                            <div class="flex-cc">
                                <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="index_type" class="col-12 col-lg-8 offset-lg-2">
                        <div class="card">
                            <div class="text-center row match-height">
                                <div class="col-lg-6 spin-mandala ty">
                                    <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                    <img src="/app-assets/images/Oops.png" alt="Oops" class="avatar">
                                </div>
                                <div class="col-lg-6">
                                    <img src="/app-assets/images/avatar.png" alt="Mandala">
                                </div>
                            </div>
                            <p class="desc" v-if="index_type != 3">
                                Oops, Looks like something has happened.<br><br> Error Message - 
                                <b class="text-center">
                                    {{ index_error }}
                                </b>
                            </p>
                            <p class="desc" v-else>
                                Dear Scholar, <br><br>
                                Congratulations on completing the skill test! Your results are pending administrative approval. Following approval, we will proceed to the interview round. Expect the meeting link in your email after administrative clearance.<br><br>Your patience is valued during this process.<br>Best of luck!
                            </p>
                            <div class="flex-cc">
                                <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                            </div>
                        </div>
                    </div>
                    <error-page v-else />
                </transition>
            </div>
        </div>
    </section>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}
.collapse-enter-active, .collapse-leave-active {
    transform-origin: top;
    transition: all 0.3s ease-in;
}

.collapse-enter-to, .collapse-leave-from {
    transform: scaleY(1);
    opacity: 1;
    filter: blur(0);
}

.collapse-enter-from, .collapse-leave-to {
    transform: scaleY(0);
    opacity: 0;
    filter: blur(5px);
}
i.fas {
    transition: all 0.2s ease-in ;
}
.flip {
    transform: rotate(180deg);
}
</style>
<script>
export default {
    data() {
        return {
            skill_token: '',
            questions: [],
            languages: [],
            language: false,
            index_error: false,
            index_type: false,
            disBtn: false,
            completed: false,
        };
    },
    created() {
        this.skill_token = this.$route.query.token
        this.langs()
    },
    computed: {
        disabled() {
            return !this.questions.every(option => option.selected !== false);
        },
        data() {
            return this.questions.map( ({crypt, selected}) => ({crypt, selected}) )
        },
    },
    methods: {
        langs() {
            axios.post('/api/scholar/get-langs', { token : this.skill_token}).then((res) => {
                if(res.data.status == 'success') 
                    this.languages = res.data.languages  
                else {
                    this.index_error = res.data.message
                    this.index_type = res.data.type
                    this.emitter.emit('scroll_section')
                    toast.fire({ icon: 'error', title: res.data.message})
                }
            })
        },
        select_lang(l) {
            this.language = l
            this.index()
        },
        index() {
            axios.post('/api/scholar/skill-tests', { token : this.skill_token, lang: this.language}).then((res) => {
                if(res.data.status == 'success') {
                    this.questions = Object.values(res.data.questions)  
                    this.questions.forEach(object => {
                        object['selected'] = false
                    });
                }
                else {
                    this.index_error = res.data.message
                    this.index_type = res.data.type
                    this.emitter.emit('scroll_section')
                    toast.fire({ icon: 'error', title: res.data.message})
                }
            })
        },
        submit() {
            this.disBtn = true
            axios.post('/api/scholar/submit-test', {token: this.skill_token, data: this.data}).then((res) => {
                this.disBtn = false
                if(res.data.status == 'success') {
                    this.completed = true                
                    this.emitter.emit('scroll_section')
                }
                else toast.fire({ icon: 'error', title: res.data.message})
            }).catch((err) => {
                this.disBtn = false
                toast.fire({ icon: 'error', title: 'Server Error Occurred'})
            })
        },
        reset_lang() {
            this.language = false
        }
    }
}
</script>