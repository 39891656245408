<template>
    <div class="main" ref="section">
        <section class="section orange pt-5">
            <!-- <div class="animated-background"></div> -->
            <div class="container">
                <form class="row" @submit.prevent="index()">
                    <div class="col-12">
                        <div class="card">
                            <div class="row">
                                <div class="col-lg-3">
                                    <div class="form-group orange">
                                        <label class="mb-2"><h6>Your Horoscope</h6></label>
                                        <v-select :options="horoscopes" :clearable="false" v-model="from"></v-select>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group orange">
                                        <label class="mb-2"><h6>Partner Horoscope</h6></label>
                                        <v-select :options="horoscopes" :clearable="false" v-model="to"></v-select>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group orange">
                                        <label class="mb-2"><h6>Choose Language</h6></label>
                                        <v-select :options="langs" :clearable="false" v-model="lang" :reduce="i => i.value"></v-select>
                                    </div>
                                </div>
                                <div class="col-lg-2 flex-sc">
                                    <button type="submit" :disabled="loading" class="btn dark mt-3">
                                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                                        <transition name="flip" mode="out-in">
                                            <span v-if="!loading">Get Result</span>
                                            <span v-else>Loading . . .</span>
                                        </transition>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <transition name="flip" mode="out-in">
                        <div class="col-12" v-if="loading">
                            <loader :text="'Loading. . .'" />
                        </div>
                        <div v-else-if="!loading && err" class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6" v-if="!mobile">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, We couldn't find the zodiac match details that you are looking for.<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!loading && !err && result" class="col-12 col-lg-10 offset-lg-1">
                            <div class="card outline" v-for="r,i in result.data">
                                <div class="compatibility-header flex-md-row">
                                    <h5 class="mb-2" v-if="lang == 'en'">{{ r.label_en }} Compatibility</h5>
                                    <h5 class="mb-2" v-else>{{ r.label_hi }} अनुकूलता</h5>
                                    <!-- <div class="progress-bar">
                                        <h6>{{ r.score }}%</h6>
                                        <span class="progress"></span>
                                    </div> -->
                                    <div class="progress-wrapper">
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow=" r.score" :aria-valuemin=" r.score" aria-valuemax="100" :style="'width: '+r.score+'%;'"></div>
                                        </div>
                                        <span class="score">{{ r.score }}%</span>
                                    </div>
                                </div>
                                <p class="description" v-if="lang == 'en'">{{ r.response_en }}</p>
                                <p class="description bb" v-else>{{ r.response_hi }}</p>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            err: false,
            loading: false,
            horoscopes: ['Aries','Taurus','Gemini','Cancer','Leo','Virgo','Libra','Scorpio','Sagittarius','Capricorn','Aquarius','Pisces'],
            response: [],
            result: null,
            langs: [{
                value: 'en',
                label: 'English',
            },{
                value: 'hi',
                label: 'Hindi',
            }],
            lang: 'en',
            from: null,
            to: null,
        };
    },
    created() {
        // this.index()
        if(this.token) 
            this.lang = this.user.language
        this.emitter.emit('scroll_section')
    },
    methods: {
        index() {
            this.err = false
            this.loading = true
            axios.post('/api/user/compatibilities', {from: this.from, to: this.to}).then((res) => {
                this.loading = false
                if(res.data.status == 'success') {
                    this.result = res.data.match
                }
                else this.err = true
            }).catch((err) => {
                this.loading = false
                toast.fire({icon: 'error', title: 'Error Occurred'}) ;
            }) ;
        },
    },
}
</script>
<style scoped>
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
</style>