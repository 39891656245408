<template>
    <section class="section orange pb-0" v-if="streams.length">
        <div class="container">
            <div class="col-12">
                <h4 class="section-title flex-bc moving-border">
                    <span>Current Live Streams</span>
                </h4>
            </div>
            <swiper
                :class="'talkbox-swiper'"
                :keyboard="streams.length > 1 ? { enabled: true } : false"
                :pagination="{ dynamicBullets: true, clickable: true }"
                :autoplay="false"
                :breakpoints="live_breakpoints"
                :navigation="false"
                :loop="false"
                :speed="500"
                :autoHeight="true"
                :modules="modules"
            >
                <swiper-slide v-for="(s,index) in streams" :key="index">
                    <!-- <div class="live-stream-box" @click="$router.push('/live-stream/'+s.call_id)"> -->
                    <div class="live-stream-box" @click="emitter.emit('showAVmodal')">
                        <img :src="s.image" alt="">
                        <div class="name">{{ s.name }}</div>
                        <div class="rating">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M8.63673 6.03914C9.29223 4.40497 9.61999 3.58789 10.1836 3.58789C10.7472 3.58789 11.0749 4.40497 11.7305 6.03914L11.761 6.11524C12.1313 7.03846 12.3165 7.50007 12.6938 7.78064C13.0712 8.06122 13.5666 8.10558 14.5574 8.19431L14.7365 8.21036C16.358 8.35558 17.1687 8.42818 17.3422 8.944C17.5157 9.45982 16.9136 10.0076 15.7094 11.1032L15.3075 11.4688C14.6979 12.0234 14.3931 12.3007 14.2511 12.6642C14.2246 12.732 14.2025 12.8014 14.1851 12.8721C14.0917 13.251 14.181 13.6533 14.3595 14.4578L14.4151 14.7082C14.7431 16.1869 14.9071 16.9262 14.6207 17.2451C14.5137 17.3642 14.3746 17.45 14.2201 17.4922C13.8066 17.605 13.2196 17.1266 12.0454 16.1699C11.2745 15.5416 10.889 15.2275 10.4464 15.1569C10.2723 15.1291 10.0949 15.1291 9.9208 15.1569C9.47822 15.2275 9.09273 15.5416 8.32175 16.1699C7.14763 17.1266 6.56056 17.605 6.14706 17.4922C5.99255 17.45 5.85346 17.3642 5.74644 17.2451C5.46004 16.9262 5.62407 16.1869 5.95213 14.7082L6.00769 14.4578C6.1862 13.6533 6.27545 13.251 6.18207 12.8721C6.16466 12.8014 6.14263 12.732 6.11613 12.6642C5.97406 12.3007 5.66927 12.0234 5.05968 11.4688L4.65778 11.1032C3.45358 10.0076 2.85147 9.45982 3.02496 8.944C3.19844 8.42818 4.00919 8.35557 5.63071 8.21036L5.80982 8.19431C6.80058 8.10558 7.29597 8.06122 7.67334 7.78064C8.05072 7.50007 8.23588 7.03846 8.60621 6.11523L8.63673 6.03914Z" fill="#FF7A4A" stroke="#38210F"/>
                            </svg>
                            <span class="value">{{ s.avg_rating || 0 }}</span>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Autoplay, Pagination, Keyboard, Navigation } from 'swiper';
    import { limit, orderBy, collection, query, onSnapshot, where} from "firebase/firestore";
</script>
<script>
export default {
    props: ['user'],
    data() {
        return {
            streams: [],
            modules: [Autoplay, Pagination, Keyboard, Navigation ],
            interval: null,
            live_breakpoints: {
                '0': {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                '768': {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                '1024': {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                '1300': {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
            },
        };
    },
    created() {
        this.index()
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        async index() {
            var colRef = query(collection(db, 'live-streams'), where('status', '<' ,3), orderBy("status"), orderBy("started_at","desc"), limit(10))
            this.interval = onSnapshot(colRef, (querySnapshot) => {
                this.streams = []
                querySnapshot.forEach((document) => {
                    var docdata = document.data();
                    this.streams.push(docdata);
                });
            });
        }
    },
}
</script>