<template>
    <section class="section orange pb-0" v-if="rooms.length">
        <div class="container">
            <div class="col-12">
                <h4 class="section-title flex-bc moving-border">
                    <span>Astro Rooms</span>
                    <router-link to="/astro-rooms" class="text-dark">View All</router-link>
                </h4>    
            </div>
            <swiper
                :class="'rooms-swiper'"
                :keyboard="rooms.length > 1 ? { enabled: true } : false"
                :pagination="{ dynamicBullets: true, clickable: true }"
                :autoplay="false"
                :breakpoints="live_breakpoints"
                :navigation="false"
                :loop="false"
                :speed="500"
                :autoHeight="true"
                :modules="modules"
            >
                <swiper-slide v-for="(r,index) in rooms" :key="index">
                    <router-link :to="'astrologers?room='+r.label" class="app-room-box">
                        <div class="img-wrapper">
                            <img :src="r.image" alt="">
                        </div>
                        <div class="name">{{ r.label }}</div>
                    </router-link>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Autoplay, Pagination, Keyboard, Navigation } from 'swiper';
</script>
<script>
export default {
    props: ['rooms'],
    data() {
        return {
            modules: [Autoplay, Pagination, Keyboard, Navigation ],
            interval: null,
            live_breakpoints: {
                '0': {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                '768': {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                '1024': {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                '1300': {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
            },
        };
    },
    created() {
    },
    beforeUnmount() {
    },
}
</script>