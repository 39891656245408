<template>
    <div class="main">
        <section class="section orange">
            <transition name="fade">
                <div class="container" v-if="!loading">
                    <transition name="grow">
                        <div class="card profile-card" v-if="user.completed">
                            <div class="content">
                                <div class="photo">
                                    <img src="/app-assets/images/h-logo.png" alt="">
                                </div>
                                <div class="name">{{ user.first_name+' '+user.last_name }}, <span class="gender">{{ user.gender }}</span></div>
                                <div>
                                    <span class="mobile" v-if="user.mobile_verified_at">{{ user.mobile }}</span>
                                    <span class="mobile" v-else><b>{{ user.mobile }}</b></span>, 
                                    <span class="email" v-if="user.email_verified_at">{{ user.email }}</span>
                                    <span class="email" v-else><b>{{ user.email }}</b></span>
                                </div>
                            </div>
                        </div>
                    </transition>
                    <div class="row">
                        <div class="col-12 col-lg-6" v-if="!mobile">
                            <div class="card">
                                <form class="row" @submit.prevent="submit">
                                    <div class="col-12 col-md-6 form-group orange required">
                                        <label>First Name</label>
                                        <input required type="text" v-model="profile.f_name" class="form-control" :class="{'is-invalid' : errors.f_name}">
                                        <span v-if="errors.f_name" class="invalid-feedback"><strong>{{ errors.f_name[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange required">
                                        <label>Last Name</label>
                                        <input required type="text" v-model="profile.l_name" class="form-control" :class="{'is-invalid' : errors.l_name}">
                                        <span v-if="errors.l_name" class="invalid-feedback"><strong>{{ errors.l_name[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Date of Birth</label>
                                        <VueDatePicker hide-offset-dates :min-date="min_date" :max-date="max_date" utc :year-range="[1920, curr_year]" prevent-min-max-navigation :clearable="false" v-model="profile.dob" :class="{'form-control is-invalid': errors.dob}" format="dd MMM, yyyy" model-type="yyyy-MM-dd" :month-change-on-scroll="false" :enable-time-picker="false"></VueDatePicker>
                                        <span v-if="errors.dob" class="invalid-feedback"><strong>{{ errors.dob[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Time of Birth</label>
                                        <VueDatePicker :clearable="false" v-model="profile.tob" :class="{'form-control is-invalid': errors.tob}"  model-type="HH:mm" format="h:mm a" time-picker></VueDatePicker>
                                        <span v-if="errors.tob" class="invalid-feedback"><strong>{{ errors.tob[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Gender</label>
                                        <v-select :options="['Male', 'Female', 'Others']" :clearable="false" v-model="profile.gender" :class="{'is-invalid' : errors.gender}" />
                                        <span v-if="errors.gender" class="invalid-feedback"><strong>{{ errors.gender[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Pincode</label>
                                        <input type="tel" maxlength="6" minlength="6" @keypress="validate" v-model.number="profile.zipcode" class="form-control" :class="{'is-invalid' : errors.zipcode}">
                                        <span v-if="errors.zipcode" class="invalid-feedback"><strong>{{ errors.zipcode[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 form-group orange">
                                        <label>Place of Birth</label>
                                        <GMapAutocomplete @placeholder="profile.pob" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" class="form-control" ></GMapAutocomplete>
                                    </div>
                                    <div class="col-12 form-group orange">
                                        <label>Address</label>
                                        <input type="text" maxlength="255" v-model="profile.address" class="form-control" :class="{'is-invalid' : errors.address}">
                                        <span v-if="errors.address" class="invalid-feedback"><strong>{{ errors.address[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Currency</label>
                                        <v-select :options="currencies" :clearable="false" :reduce="i => i.id" v-model="profile.currency_id" :class="{'is-invalid' : errors.currency_id}" />
                                        <span v-if="errors.currency_id" class="invalid-feedback"><strong>{{ errors.currency_id[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Api Language</label>
                                        <v-select :options="api_langs" :clearable="false" :reduce="i => i.value" v-model="profile.language" :class="{'is-invalid' : errors.language}" />
                                        <span v-if="errors.language" class="invalid-feedback"><strong>{{ errors.language[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group">
                                        <label for="">&nbsp;</label>
                                        <button :disabled="disBtn == 1" type="submit" class="btn block orange-dark text-uppercase">
                                            <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="card header" v-if="!user.email_verified_at || !user.mobile_verified_at">
                                <div class="card-header">
                                    <h4 class="title">Validate {{ user.type == 'mobile' ? 'Email' : 'Mobile' }}</h4>
                                </div>
                                <div class="card-body">
                                    <form method="POST" class="row" @submit.prevent="verify_otp">
                                        <div class="col-12 col-md-8 form-group orange required" v-if="user.type == 'mobile'">
                                            <input type="email" v-model="profile.email" class="form-control" :class="{'is-invalid' : errors.email}" required placeholder="Enter Email">
                                            <span v-if="errors.email" class="invalid-feedback"><strong>{{ errors.email[0] }}</strong></span>
                                        </div>
                                        <div class="col-12 col-md-8 form-group input-group orange required" v-else>
                                            <div class="input-group-append">
                                                <select required class="form-control" v-model="profile.country_code">
                                                    <option v-for="(c,index) in otp_codes" :key="index" :value="c">{{ c }}</option>
                                                </select>
                                            </div>
                                            <input type="tel" maxlength="10" minlength="10" @keypress="validate" v-model="profile.mobile" class="form-control" :class="{'is-invalid' : errors.mobille}" required placeholder="Enter Mobile Number">
                                            <span v-if="errors.mobille" class="invalid-feedback"><strong>{{ errors.mobille[0] }}</strong></span>
                                        </div>
                                        <div class="col-12 col-md-4 form-group">
                                            <button :disabled="disBtn == 2 || otp_sent" type="button" @click="send_otp" :class="{'mx-auto' :mobile}" class="btn block orange-dark">
                                                <i v-if="disBtn == 2" class="fas fa-spinner fa-spin"></i>
                                                SEND OTP
                                            </button>
                                        </div>
                                        <transition-group name="grow">
                                            <div class="col-12 form-group orange text-center" v-if="otp_sent">
                                                <div class="flex-cc gap-3">
                                                    <input type="password" maxlength="1" minlength="1" ref="otp_1" @keypress="validate" @input="onInput($event, 1)" @keydown.backspace="onBackspace($event, 1)" v-model="verify.otp_1" class="form-control otp-input">
                                                    <input type="password" maxlength="1" minlength="1" ref="otp_2" @keypress="validate" @input="onInput($event, 2)" @keydown.backspace="onBackspace($event, 2)" v-model="verify.otp_2" class="form-control otp-input">
                                                    <input type="password" maxlength="1" minlength="1" ref="otp_3" @keypress="validate" @input="onInput($event, 3)" @keydown.backspace="onBackspace($event, 3)" v-model="verify.otp_3" class="form-control otp-input">
                                                    <input type="password" maxlength="1" minlength="1" ref="otp_4" @keypress="validate" @input="onInput($event, 4)" @keydown.backspace="onBackspace($event, 4)" v-model="verify.otp_4" class="form-control otp-input">
                                                </div>
                                            </div>
                                            <div class="col-md-6 offset-md-3" v-if="otp_sent">
                                                <button :disabled="disBtn == 3" type="submit" class="btn block orange-dark text-uppercase mt-3"> <i v-if="disBtn == 3" class="fas fa-spinner fa-spin"></i> verify and submit</button>
                                            </div>
                                        </transition-group>
                                    </form>
                                </div>
                            </div>
                            <div class="card" v-if="mobile">
                                <form class="row" @submit.prevent="submit">
                                    <div class="col-12 col-md-6 form-group orange required">
                                        <label>First Name</label>
                                        <input required type="text" v-model="profile.f_name" class="form-control" :class="{'is-invalid' : errors.f_name}">
                                        <span v-if="errors.f_name" class="invalid-feedback"><strong>{{ errors.f_name[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange required">
                                        <label>Last Name</label>
                                        <input required type="text" v-model="profile.l_name" class="form-control" :class="{'is-invalid' : errors.l_name}">
                                        <span v-if="errors.l_name" class="invalid-feedback"><strong>{{ errors.l_name[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Date of Birth</label>
                                        <VueDatePicker hide-offset-dates :min-date="min_date" :max-date="max_date" utc prevent-min-max-navigation v-model="profile.dob" :class="{'form-control is-invalid': errors.dob}" format="dd MMM, yyyy" model-type="yyyy-MM-dd" :month-change-on-scroll="false" :enable-time-picker="false"></VueDatePicker>
                                        <span v-if="errors.dob" class="invalid-feedback"><strong>{{ errors.dob[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Time of Birth</label>
                                        <VueDatePicker v-model="profile.tob" :class="{'form-control is-invalid': errors.tob}"  model-type="HH:mm" format="h:mm a" time-picker></VueDatePicker>
                                        <span v-if="errors.tob" class="invalid-feedback"><strong>{{ errors.tob[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 form-group orange">
                                        <label>Place of Birth</label>
                                        <GMapAutocomplete @placeholder="profile.pob" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" class="form-control" ></GMapAutocomplete>
                                    </div>
                                    <div class="col-12 form-group orange">
                                        <label>Address</label>
                                        <input type="text" maxlength="255" v-model="profile.address" class="form-control" :class="{'is-invalid' : errors.address}">
                                        <span v-if="errors.address" class="invalid-feedback"><strong>{{ errors.address[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Pincode</label>
                                        <input type="tel" maxlength="6" minlength="6" @keypress="validate" v-model.number="profile.zipcode" class="form-control" :class="{'is-invalid' : errors.zipcode}">
                                        <span v-if="errors.zipcode" class="invalid-feedback"><strong>{{ errors.zipcode[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Currency</label>
                                        <v-select :options="currencies" :clearable="false" :reduce="i => i.id" v-model="profile.currency_id" :class="{'is-invalid' : errors.currency_id}" />
                                        <span v-if="errors.currency_id" class="invalid-feedback"><strong>{{ errors.currency_id[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-md-6 form-group orange">
                                        <label>Api Language</label>
                                        <v-select :options="api_langs" :clearable="false" :reduce="i => i.value" v-model="profile.language" :class="{'is-invalid' : errors.language}" />
                                        <span v-if="errors.language" class="invalid-feedback"><strong>{{ errors.language[0] }}</strong></span>
                                    </div>
                                    <div class="col-md-4 offset-md-4 form-group">
                                        <button :disabled="disBtn == 1" type="submit" class="btn small block orange-dark text-uppercase mt-3">
                                            <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="card settings-card">
                                <ul>
                                    <li>
                                        <div class="form-group orange">
                                            <label class="mb-2"><b>Notifications</b></label>
                                        </div>
                                        <div class="flex-bc">
                                            Astromall
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" :checked="astro_notif" @click="update_astro_notif" id="astro_notif" type="checkbox">
                                                <label class="form-check-label" for="astro_notif"></label>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <router-link to="/manage-privacy" class="form-group orange">
                                            <label><b>Manage Your Privacy</b></label>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/tnc" class="form-group orange">
                                            <label><b>Terms and Conditions</b></label>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/privacy-policy" class="form-group orange">
                                            <label><b>Privacy Policy</b></label>
                                        </router-link>
                                    </li>
                                </ul>
                                <button class="btn small col-8 col-md-4 mx-auto orange-dark" type="button" @click="emitter.emit('logout')">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="7" cy="7" rx="7" ry="7" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 22.4062 19.9499)" fill="#FFF384"/>
                                        <path d="M6.90625 10.4499L4.40625 12.9499M4.40625 12.9499L6.90625 15.4499M4.40625 12.9499H14.4062" stroke="#38210F" stroke-linecap="round"/>
                                    </svg>
                                    LOGOUT
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <loader :text="loader_text" v-else />
            </transition>
        </section>
    </div>
</template>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
        filter: blur(2px);
    }
    .grow-enter-active, .grow-leave-active {
        transition: all 0.25s ease-out;
    }
    .grow-enter-from {
        opacity: 0;
        filter: blur(15px);
        transform: scale(0);
        transform-origin: center;
    }
    .grow-leave-to {
        opacity: 0;
        filter: blur(15px);
        transform: scale(0);
        transform-origin: center;
    }
</style>
<script>
export default {
    props: ['user','currencies', 'otp_codes','api_langs'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            errors: [],
            curr_year: moment().format('YYYY'),
            focus: false,
            astro_notif: false,
            loading: false,
            disBtn: false,
            otp_sent: false,
            otpHash: '',
            min_date: moment('1920', 'YYYY').startOf('year').format('YYYY-MM-DD'),
            max_date: moment().format('YYYY-MM-DD'),
            loader_text: 'Loading . . .',
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            profile: new Form({
                f_name: '',
                l_name: '',
                email: '',
                mobile: '',
                dob: '',
                gender: 'Male',
                tob: '',
                pob: '',
                language: 'en',
                country_code: '+91',
                address: '',
                zipcode: '',
                currency_id: '',
            }),
            verify: new Form({
                otp_1: '',
                otp_2: '',
                otp_3: '',
                otp_4: '',
            }),
        };
    },
    computed: {
        classes() {
            if(this.focus) return 'form-control mb-3 focus'
            else return 'form-control mb-3'
        }
    },
    created() {
        this.emitter.emit('talk_section', true)
        this.emitter.emit('blog_section', true)
        // this.emitter.emit('scroll_section')
    },
    beforeUnmount() {
        this.emitter.emit('talk_section', false)
        this.emitter.emit('blog_section', false)
    },
    mounted() {
        if(this.token) {
            this.index()
        } else this.loading = true
    },
    methods: {
        index() {
            this.profile.f_name = this.user.first_name
            this.profile.l_name = this.user.last_name
            this.profile.email = this.user.email
            this.profile.mobile = this.user.mobile
            this.profile.dob = this.user.dob
            this.profile.tob = this.user.tob
            this.profile.pob = this.user.pob
            this.profile.language = this.user.language
            this.profile.address = this.user.address
            this.profile.zipcode = this.user.zipcode
            this.profile.currency_id = this.currencies?.find(i => i.symbol == this.user?.currency?.symbol)?.id
            this.astro_notif = this.user.astro_notif
        },
        submit() {
            // if(!this.profile.pob) {
            //    toast.fire({'icon': 'info', 'title': 'Please Select Location from Dropdown!'})
            //    return ;
            // }
            this.disBtn = 1 ;
            axios.post('/api/user/save-profile', this.profile, this.config).then((res) => {
                this.disBtn = false ;
                if(res.data.status == 'success') {
                    this.errors = []
                    this.emitter.emit("get_user")
                    toast.fire({'icon': 'success', 'title': 'Profile Details Saved!'})
                }
                else {
                    toast.fire({'icon': 'error', 'title': 'Error Occurred!'})
                }
            }).catch((e) => {
                if (e.response.status === 422) this.errors = e.response.data.errors;
                this.disBtn = false ;
                toast.fire({'icon': 'error', 'title': 'Error Occurred!'})
            })
        },
        send_otp() {
            this.disBtn = 2 ;
            if(this.user.type == 'mobile')
                var data = { email: this.profile.email}
            else
                var data = { mobile: this.profile.mobile, country_code: this.profile.country_code}
            axios.post('/api/user/send-profile-otp', data, this.config).then((res) => {
                this.disBtn = false ;
                if(res.data.status == 'success') {
                    this.otpHash = res.data.otpHash
                    this.otp_sent = true
                    toast.fire({'icon': 'success', 'title': 'OTP Sent!'})
                }
                else {
                    toast.fire({'icon': 'info', 'title': res.data.message})
                }
            }).catch((e) => {
                this.disBtn = false ;
                toast.fire({'icon': 'error', 'title': 'Error Occurred!'})
            })
        },
        verify_otp() {
            this.disBtn = 3 ;
            var data = {
                verifyOtp: this.verify,
                otpHash: this.otpHash,
                // type: this.user.type,
            }
            axios.post('/api/user/verify-profile-otp', data, this.config).then((res) => {
                this.verify.reset()
                this.disBtn = false ;
                this.otp_sent = false
                this.emitter.emit("get_user")
                toast.fire({'icon': 'success', 'title': 'OTP Verified!'})
            }).catch((e) => {
                this.disBtn = false ;
                toast.fire({'icon': 'error', 'title': 'Error Occurred!'})
            })

        },
        getAddressData(place) {
            this.profile.pob = place.formatted_address
        },
        onInput(event, index) {
            const nextInput = this.$refs[`otp_${index + 1}`];
            if (event.target.value.length === 1 && nextInput) {
                nextInput.focus();
            }
        },
        onBackspace(event, index) {
            const prevInput = this.$refs[`otp_${index - 1}`];
            if (event.target.value.length === 0 && prevInput) {
                prevInput.focus();
            }
        },
        update_astro_notif() {
            this.astro_notif = !this.astro_notif
            axios.get('/api/user/update-astro-notif', this.config).then((res) => {
                this.emitter.emit("get_user")
            }).catch((err) => {
                toast.fire({'icon': 'error', 'title': 'Error Occurred!'})
            })
        },
    },
}
</script>